module.exports = {
    EXPRESS_PORT: 4000,
    ONE_WIRE: {
        HALL: '/sys/bus/w1/devices/28-0000066b4a77/temperature',
        ROOM: '/sys/bus/w1/devices/28-e3141e0264ff/temperature',
        STREET: '/sys/bus/w1/devices/28-021564c7a1ff/temperature',
        ROOM2: '/sys/bus/w1/devices/28-f25ae70264ff/temperature',


    },
    ONE_WIRE_DELAY: 20000,
    LOG:{
        filename:'/var/log/plan/set.log',
        limit: 10*Math.pow(1024,2), //10Mb
    }
}
