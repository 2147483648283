import { createRouter, createWebHistory } from 'vue-router'
//import HomeView from '@/views/HomeView.vue'
import FirstView from '@/views/FirstView.vue'
import SecondView from '@/views/SecondView.vue'
import AnalyzeView from '@/views/AnalyzeView.vue'
const routes = [
  {
    path: '/',
    name: 'home',
    alias: '/first',
    component: FirstView
  },
  { path:'/first',
    name: 'first',
    component: FirstView
  },
  { path:'/second',
    name: 'second',
    component: SecondView
  },
  { path:'/analyze',
    name: 'analyze',
    component: AnalyzeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
