<template>
  <nav>
    <router-link to="/first">1 этаж</router-link> |
    <router-link to="/second">2 этаж</router-link> |
    <router-link to="/analyze">Анализ</router-link>
    <!-- |
    <router-link to="/about">About</router-link> -->
  </nav>
  <router-view/>
</template>
<style lang="sass">
svg.plan
  margin: 0 20%


.wall
  fill: black
  stroke: black
  stroke-width: 7px

.window
  fill: white
  stroke: black
  stroke-width: 7px

.door
  fill: #dddddd
  stroke: black
  stroke-width: 1px

.fill
  fill: black

.temperature
  font-size: 200px
  stroke: black
</style>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
