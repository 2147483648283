<template>
    <h1>Первый этаж</h1>
    <Plan1/>
</template>

<script>
    import Plan1 from '@/components/Plan1.vue'
    export default {
        name: "FirstView",
        components:{
            Plan1
        }
    }
</script>

<style scoped>

</style>
