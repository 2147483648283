<template>
    <h1>Analyze</h1>
</template>

<script>
export default {
    name: "AnalyzeView"
}
</script>

<style scoped>

</style>