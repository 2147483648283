<template>
    <h1>Второй этаж</h1>
    <Plan2/>
</template>

<script>
    import Plan2 from '@/components/Plan2.vue'
    export default {
        name: "SecondView",
        components:{
            Plan2
        }
    }
</script>

<style scoped>

</style>
